/*HEADER*/
.header {
  text-align: center;
  margin-top: 90px; }

h1 {
  font-family: 'Just Sunday';
  font-weight: bold;
  font-style: normal;
  font-size: 200px;
  color: #fff; }

.contacts {
  display: flex;
  justify-content: center;
  font-size: 24px;
  text-transform: uppercase;
  font-weight: 300;
  font-family: 'Raleway', sans-serif;
  letter-spacing: .7px; }
  .contacts a {
    margin-top: 90px;
    color: #fff;
    text-decoration: none;
    padding: 0 20px;
    -webkit-transition: all .4s;
    transition: all .4s;
    cursor: pointer; }
    .contacts a:first-child {
      font-weight: 600;
      border-right: 2px solid #fff; }
    .contacts a:hover {
      text-decoration: underline; }

@media (max-width: 767px) {
  .header {
    text-align: center;
    margin-top: 30px; }
  h1 {
    font-family: 'Just Sunday';
    font-weight: bold;
    font-style: normal;
    font-size: 100px;
    color: #fff; }
  .contacts {
    display: flex;
    justify-content: center;
    font-size: 20px;
    text-transform: uppercase;
    color: #fff; }
    .contacts a {
      margin-top: 30px;
      text-decoration: none;
      padding: 0 20px;
      -webkit-transition: all .4s;
      transition: all .4s;
      cursor: pointer; }
      .contacts a:first-child {
        border-right: 2px solid #fff; }
      .contacts a:hover {
        text-decoration: underline; } }

* {
  box-sizing: border-box; }

body .logo {
  display: block;
  margin: 40px auto;
  -webkit-transition: all .4s;
  transition: all .4s; }
  body .logo:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1); }

body .row {
  display: flex;
  justify-content: center; }

body .loading {
  position: absolute;
  top: 70%;
  left: 50%;
  -webkit-transform: translate(-50%, -70%);
          transform: translate(-50%, -70%); }

body .mobile-button {
  margin-top: 30px;
  display: flex; }
  body .mobile-button button {
    margin: 10px;
    text-decoration: none;
    padding: 5px;
    opacity: .6;
    font-size: 22px;
    color: #fff;
    text-transform: uppercase;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    font-family: 'Raleway', sans-serif;
    font-weight: 300;
    letter-spacing: 3.5px;
    background-color: transparent;
    border: none; }
    body .mobile-button button:focus {
      opacity: 1;
      outline: none;
      -webkit-transform: scale(1.1);
              transform: scale(1.1); }
    body .mobile-button button:hover {
      opacity: 1; }

.photo-style {
  margin: 10px; }

.gallery {
  margin-top: 30px; }

@media screen and (max-width: 1050px) {
  body ul {
    margin-top: 30px;
    text-align: center; }
  body .mobile-button {
    margin-top: 30px;
    display: block; }
    body .mobile-button button {
      display: block;
      margin: 10px auto;
      text-decoration: none;
      padding: 5px;
      opacity: .6;
      font-size: 22px;
      color: #fff;
      text-transform: uppercase;
      -webkit-transition: 0.4s;
      transition: 0.4s;
      font-family: 'Raleway', sans-serif;
      font-weight: 300;
      letter-spacing: 3.5px;
      background-color: transparent;
      border: none; }
      body .mobile-button button:focus {
        opacity: 1;
        outline: none;
        -webkit-transform: scale(1.1);
                transform: scale(1.1); }
  body .gallery {
    margin-top: 30px; } }

html, body{
    margin: 0;
    background-color: #000;
}
