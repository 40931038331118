* {
  box-sizing: border-box;

}

// General styles
body {

  .logo {
    display: block;
    margin: 40px auto;
    transition: all .4s;

    &:hover {
      transform: scale(1.1);
    }
  }

  .row {
    display: flex;
    justify-content: center;
  }

  .loading {
    position: absolute;
    top: 70%;
    left: 50%;
    transform: translate(-50%, -70%);
  }


  .mobile-button {
    margin-top: 30px;
    display: flex;

    button {
      margin: 10px;
      text-decoration: none;
      padding: 5px;
      opacity: .6;
      font-size: 22px;
      color: #fff;
      text-transform: uppercase;
      transition: 0.4s;
      font-family: 'Raleway', sans-serif;
      font-weight: 300;
      letter-spacing: 3.5px;
      background-color: transparent;
      border: none;

      &:focus {
        opacity: 1;
        outline: none;
        transform: scale(1.1);
      }

      &:hover {
        opacity: 1;
      }
    }
  }

}

.photo-style {
  margin: 10px;
}

.gallery {
  margin-top: 30px;
}

// Breakpoint variant
@media screen and (max-width: 1050px) {

  body {

    ul {
      margin-top: 30px;
      text-align: center;
    }

    .mobile-button {
      margin-top: 30px;
      display: block;

      button {
        display: block;
        margin: 10px auto;
        text-decoration: none;
        padding: 5px;
        opacity: .6;
        font-size: 22px;
        color: #fff;
        text-transform: uppercase;
        transition: 0.4s;
        font-family: 'Raleway', sans-serif;
        font-weight: 300;
        letter-spacing: 3.5px;
        background-color: transparent;
        border: none;

        &:focus {
          opacity: 1;
          outline: none;
          transform: scale(1.1);
        }
      }
    }

    .gallery {
      margin-top: 30px;
    }

  }
}