/*HEADER*/

.header {
  text-align: center;
  margin-top: 90px;
}

h1 {
  font-family: 'Just Sunday';
  font-weight: bold;
  font-style: normal;
  font-size: 200px;
  color: #fff;
}

.contacts {
  display: flex;
  justify-content: center;
  font-size: 24px;
  text-transform: uppercase;
  font-weight: 300;
  font-family: 'Raleway', sans-serif;
  letter-spacing: .7px;

  a {
    margin-top: 90px;
    color: #fff;
    text-decoration: none;
    padding: 0 20px;
    transition: all .4s;
    cursor: pointer;

    &:first-child {
      font-weight: 600;
      border-right: 2px solid #fff;
    }

    &:hover {
      text-decoration: underline;
    }
  }
}

@media (max-width: 767px) {

  .header {
    text-align: center;
    margin-top: 30px;
  }

  h1 {
    font-family: 'Just Sunday';
    font-weight: bold;
    font-style: normal;
    font-size: 100px;
    color: #fff;
  }

  .contacts {
    display: flex;
    justify-content: center;
    font-size: 20px;
    text-transform: uppercase;
    color: #fff;

    a {
      margin-top: 30px;
      text-decoration: none;
      padding: 0 20px;
      transition: all .4s;
      cursor: pointer;

      &:first-child {
        border-right: 2px solid #fff;

      }

      &:hover {
        text-decoration: underline;
      }
    }
  }
}